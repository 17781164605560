import { useState, useRef, useMemo, useCallback } from 'react';
import {
  CnDrawer,
  CnBox,
  CnIcon,
  CnInput,
  CnCard,
  Field,
  CnLoading,
  CnResult,
  CnButton,
} from '@cainiaofe/cn-ui';
import TabCard from './tab-card';
import Return from './return';
import {
  formatDate,
  formatDateTime,
  getDataSourceLabel,
  imageOrVideoStyles,
  longTextRender,
  useList,
} from './utils';
import {
  close,
  dingCurrentPerson,
  loadList,
  operate,
  reApply,
  statusEnum,
  useTab,
} from '../../services';
import { bossOrigin, isEn, locale } from '../../shared';
import { isMobile } from '..';

const flexBox = {
  direction: 'row',
};

const labelStyle = {
  whiteSpace: 'nowrap',
};

// eslint-disable-next-line react/display-name, react/prop-types
export default function ({ onClose, visible, employeeId }) {
  const defaultValues = {
    isCreate: true,
    distributed: 1,
    fecthTab: true,
    isAwait: visible === 'isAwait' ? true : undefined,
    isSystemQuestion: true,
  };
  const field = useRef(Field.useField({ values: defaultValues }));
  const [params, setParams] = useState(defaultValues);
  const noFecth = useMemo(
    () => !params.isConfirmed && !params.isAwait && !params.isRectified,
    [params],
  );
  const {
    data: { tableData = [] },
    setData,
    search,
    loading,
  } = useList(loadList, {
    field: field.current,
    noFecth,
    noScroll: false,
    isFilter: true,
    modalId: 'problem-list-container',
  });
  const { tab, initValues, loading: tabLoading } = useTab(params);

  const reloadData = useCallback(() => {
    setParams((p) => ({ ...p, fecthTab: true }));
    search();
  }, [search]);

  const timer = useRef();
  const searchTimer = (e) => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (e) {
        setParams((p) => ({ ...p, fecthTab: true }));
      }
      search();
    }, 300);
  };

  const [agreeLoadings, setAgreeLoadings] = useState({});
  const [dingLoadings, setDingLoadings] = useState({});
  const [closeLoadings, setCloseLoadings] = useState({});
  const [reApplyLoadings, setReApplyLoadings] = useState({});

  const [returnBizId, setReturnBizId] = useState();

  const seeDetail = (bizId) => {
    window.open(
      `${bossOrigin}${window.dworkPathnameMap.site}/system/problem/management?isAll=true&bizId=${bizId}`,
    );
  };

  return (
    <CnDrawer
      visible={!!visible}
      footer={false}
      size='small'
      style={isMobile ? { maxWidth: '100%', width: '100%' } : { width: 404 }}
      onClose={onClose}
      transparencyMask
      noCard
      headerStyle={{ height: 94 }}
      title={
        <CnBox
          direction='row'
          align='center'
          className='intelli-assistant-panel-hint'
          spacing={6}
        >
          <img
            src='https://img.alicdn.com/imgextra/i4/O1CN01Z4U8Jn1kQZbKo8p8r_!!6000000004678-2-tps-236-236.png'
            style={{ width: 46, height: 46 }}
          />
          <div className={`userName ${isEn ? 'en' : ''}`}>
            {locale.estateAsst}
          </div>
          <div className='content'>
            <div>
              {window?._GATEWAY_PF?.userName
                ? `${window?._GATEWAY_PF?.userName}${locale.dou}`
                : ''}
              {new Date().getHours() > 12 ? locale.afternoon : locale.morning}
            </div>
            <CnBox>
              {(() => {
                if (params.isConfirmed && tab[0].number) {
                  return `${locale.thereAre} ${tab[0].number} ${locale.ticketConfirm}`;
                }
                if (params.isAwait && tab[1].number) {
                  return `${locale.thereAre} ${tab[1].number} ${locale.ticketSolving}`;
                }
                if (params.isRectified && tab[2].number) {
                  return `${locale.thereAres} ${tab[2].number} ${locale.ticketClosed}`;
                }
                return null;
              })()}
            </CnBox>
          </div>
        </CnBox>
      }
    >
      <CnBox style={{ position: 'sticky', top: 0 }}>
        <CnInput
          hasClear
          size='large'
          placeholder={locale.search}
          onChange={(v) => {
            params.content = v || undefined;
            field.current.setValues({ content: v || undefined });
          }}
          onPressEnter={searchTimer}
          onBlur={searchTimer}
          innerAfter={
            <CnIcon
              type='search'
              onClick={searchTimer}
              style={{ margin: 4, cursor: 'pointer' }}
            />
          }
          style={{ width: '100%', marginBottom: 16, borderRadius: 8 }}
        />
        <TabCard
          noFecth={noFecth}
          params={params}
          setParams={setParams}
          search={(p, noSearch) => {
            field.current.setValues(p);
            if (!noSearch) {
              setData((d) => ({ ...d, tableData: [] }));
              searchTimer();
              setReturnBizId();
            }
          }}
          tab={tab}
          initValues={initValues}
          loading={tabLoading}
        />
      </CnBox>
      <CnLoading
        visible={loading}
        className='cn-loading-block'
        style={{
          display: 'block',
          height: 'calc(100% - 220px)',
        }}
      >
        {tableData?.length ? (
          <CnBox
            id='problem-list-container'
            style={{
              height: '100%',
              overflowY: 'auto',
              position: 'relative',
            }}
          >
            {tableData?.map((item, index) => {
              if (returnBizId === item.bizId) {
                return (
                  <Return
                    item={item}
                    setReturnBizId={setReturnBizId}
                    reloadData={reloadData}
                  />
                );
              }
              return (
                <CnCard
                  style={{
                    position: 'relative',
                    marginBottom: tableData.length - 1 === index ? 10 : 16,
                  }}
                >
                  <CnBox
                    x-if={params.isRectified}
                    className='status'
                    style={{
                      color: getDataSourceLabel(
                        statusEnum,
                        item.status,
                        'color',
                      ),
                      backgroundColor: getDataSourceLabel(
                        statusEnum,
                        item.status,
                        'bgColor',
                      ),
                    }}
                  >
                    {item.statusName}
                  </CnBox>
                  <CnBox
                    {...flexBox}
                    style={{
                      paddingTop: params.isRectified ? 8 : 0,
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }}
                    onClick={() => seeDetail(item.bizId)}
                  >
                    <div style={labelStyle}>
                      {locale.problemUrl}
                      {locale.mao}
                    </div>
                    <div>{longTextRender(item.url)}</div>
                  </CnBox>
                  <CnBox
                    {...flexBox}
                    style={{ overflow: 'hidden', cursor: 'pointer' }}
                    onClick={() => seeDetail(item.bizId)}
                  >
                    <div style={labelStyle}>
                      {locale.problemDescription}
                      {locale.mao}
                    </div>
                    <div>{longTextRender(item.content)}</div>
                  </CnBox>
                  <CnBox
                    {...flexBox}
                    style={{ cursor: 'pointer' }}
                    onClick={() => seeDetail(item.bizId)}
                  >
                    <div style={labelStyle}>
                      {locale.createTime}
                      {locale.mao}
                    </div>
                    <div>{formatDateTime(item.gmtCreate)}</div>
                  </CnBox>
                  <CnBox
                    {...flexBox}
                    wrap
                    style={{ paddingTop: 8 }}
                    x-if={!!item?.attachmentIds?.length}
                  >
                    {item.attachmentIds?.map((img) => (
                      <img
                        {...imageOrVideoStyles(img, {
                          src: item.attachmentIds.map(
                            (ele) => ele.imgURL || ele.previewUrl,
                          ),
                          value: img.imgURL || img.previewUrl,
                          hasDownload: false,
                        })}
                      />
                    ))}
                  </CnBox>
                  {params.isAwait ? (
                    <CnBox
                      {...flexBox}
                      style={{ cursor: 'pointer' }}
                      onClick={() => seeDetail(item.bizId)}
                    >
                      <div style={labelStyle}>
                        {locale.handler}
                        {locale.mao}
                      </div>
                      <div>{item.handlerName}</div>
                    </CnBox>
                  ) : (
                    <>
                      <CnBox
                        {...flexBox}
                        style={{ overflow: 'hidden', cursor: 'pointer' }}
                        x-if={item.dealExplain}
                        onClick={() => seeDetail(item.bizId)}
                      >
                        <div style={labelStyle}>
                          {locale.solution}
                          {locale.mao}
                        </div>
                        <div>{longTextRender(item.dealExplain)}</div>
                      </CnBox>
                      <CnBox
                        {...flexBox}
                        wrap
                        style={{ paddingTop: 8 }}
                        x-if={!!item?.dealAttachmentIds?.length}
                      >
                        {item.dealAttachmentIds?.map((img) => (
                          <img
                            {...imageOrVideoStyles(img, {
                              src: item.dealAttachmentIds.map(
                                (ele) => ele.imgURL || ele.previewUrl,
                              ),
                              value: img.imgURL || img.previewUrl,
                              hasDownload: false,
                            })}
                          />
                        ))}
                      </CnBox>
                    </>
                  )}
                  <CnBox
                    {...flexBox}
                    x-if={item.deadline}
                    style={{ cursor: 'pointer' }}
                    onClick={() => seeDetail(item.bizId)}
                  >
                    <div style={labelStyle}>
                      {locale.deadline}
                      {locale.mao}
                    </div>
                    <div>{formatDate(item.deadline)}</div>
                  </CnBox>
                  <CnBox
                    {...flexBox}
                    justify='flex-end'
                    spacing={8}
                    x-if={params.isConfirmed}
                  >
                    <CnButton
                      type='primary'
                      loading={agreeLoadings[item.bizId]}
                      onClick={() => {
                        setAgreeLoadings({ [item.bizId]: true });
                        operate(
                          {
                            attachmentDocs: [],
                            instruction: locale.agreeInstruction,
                            node: 'CONFIRMATION',
                            businessId: item.bizId,
                            operateType: 'AGREE',
                          },
                          locale.agreeProblemMsg,
                        )
                          .then((success) => {
                            success && setTimeout(() => reloadData(), 500);
                          })
                          .finally(() => setAgreeLoadings({}));
                      }}
                    >
                      {locale.confirm}
                    </CnButton>
                    <CnButton
                      disabled={returnBizId}
                      onClick={() => setReturnBizId(item.bizId)}
                    >
                      {locale.supplement}
                    </CnButton>
                  </CnBox>
                  <CnBox
                    {...flexBox}
                    justify='flex-end'
                    spacing={8}
                    x-if={params.isAwait}
                  >
                    <CnButton
                      type='primary'
                      loading={dingLoadings[item.bizId]}
                      onClick={() => {
                        setDingLoadings({ [item.bizId]: true });
                        dingCurrentPerson({ bizId: item.bizId }).finally(() =>
                          setDingLoadings({}),
                        );
                      }}
                    >
                      {locale.ding}
                    </CnButton>
                    <CnButton
                      x-if={item.creator === employeeId}
                      type='primary'
                      loading={closeLoadings[item.bizId]}
                      onClick={() => {
                        setCloseLoadings({ [item.bizId]: true });
                        close({
                          attachmentDocs: [],
                          instruction: locale.closeInstruction,
                          businessId: item.bizId,
                          operateType: 'CLOSE',
                        })
                          .then((success) => {
                            success && setTimeout(() => reloadData(), 500);
                          })
                          .finally(() => setCloseLoadings({}));
                      }}
                    >
                      {locale.close}
                    </CnButton>
                  </CnBox>
                  <CnBox
                    {...flexBox}
                    justify='flex-end'
                    spacing={8}
                    x-if={
                      params.isRectified &&
                      item.status === 'CLOSED' &&
                      item.creator === employeeId
                    }
                  >
                    <CnButton
                      loading={reApplyLoadings[item.bizId]}
                      onClick={() => {
                        setReApplyLoadings({ [item.bizId]: true });
                        reApply({
                          businessId: item.bizId,
                          operateType: 'REAPPLY',
                        })
                          .then((success) => {
                            success && setTimeout(() => reloadData(), 500);
                          })
                          .finally(() => setReApplyLoadings({}));
                      }}
                    >
                      {locale.reopen}
                    </CnButton>
                  </CnBox>
                </CnCard>
              );
            })}
          </CnBox>
        ) : (
          <CnResult
            type='empty'
            subTitle={null}
            title={<span>{locale.empty}</span>}
          />
        )}
      </CnLoading>
    </CnDrawer>
  );
}
