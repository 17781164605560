import { ConfigProvider } from '@cainiaofe/cn-ui';
import { componentName } from 'src/const';
import View from 'src/view';
import { store } from '@ice/stark-data';
// eslint-disable-next-line import/no-cycle
import { customGetMenu, coneContainerCustomShell } from './custom';
const Index = ConfigProvider.config(View, {
    componentName,
});
Index.displayName = componentName;
// 自定义菜单
store.set('custom-get-menu', customGetMenu);
// 自定义shell
if (!location.href.includes('hideCustomShell=true')) {
    store.set('cone-container-custom-shell', coneContainerCustomShell);
}
export default Index;
