/* eslint-disable react/prop-types */
import { useState, useRef } from 'react';
import {
  CnBox,
  CnCard,
  Field,
  CnButton,
  Form,
  CnMessage,
  CnInputTextArea,
} from '@cainiaofe/cn-ui';
import ChunkUpload from './chunk-upload';
import { imageOrVideoStyles, longTextRender, validateField } from './utils';
import { operate } from '../../services';
import { locale } from '../../shared';
import { CnCol, CnRow } from '../../components/CnGrid';
import './index.scss';

const flexBox = {
  direction: 'row',
};

const labelStyle = {
  whiteSpace: 'nowrap',
};

// eslint-disable-next-line react/display-name
export default function (props) {
  const { reloadData, item, setReturnBizId } = props;
  const returnField = useRef(Field.useField());
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [returnLoadings, setReturnLoadings] = useState({});
  const cancel = () => {
    returnField.current.reset();
    setFiles([]);
    setReturnBizId();
  };

  return (
    <CnCard title={locale.supplyInfo}>
      <Form fullWidth field={returnField.current} useLabelForErrorMessage>
        <CnMessage type='notice'>{locale.supplyInfoNote}</CnMessage>
        <CnBox {...flexBox} style={{ paddingTop: 12 }}>
          <div style={labelStyle}>
            {locale.problemDescription}
            {locale.mao}
          </div>
          <div>{longTextRender(item.content)}</div>
        </CnBox>
        <CnBox
          {...flexBox}
          wrap
          style={{ paddingTop: 12 }}
          x-if={!!item?.attachmentIds?.length}
        >
          {item.attachmentIds?.map((img) => (
            <img
              {...imageOrVideoStyles(img, {
                src: item.attachmentIds.map(
                  (ele) => ele.imgURL || ele.previewUrl,
                ),
                value: img.imgURL || img.previewUrl,
                hasDownload: false,
              })}
            />
          ))}
        </CnBox>
        <CnRow wrap>
          <CnCol span={24}>
            <Form.Item label={locale.supplyInfo} required name='instruction'>
              <CnInputTextArea
                placeholder={locale.wordLimit500}
                maxLength={500}
                showLimitHint
                rows={15}
              />
            </Form.Item>
          </CnCol>
          <CnCol span={24}>
            <ChunkUpload
              defaultValue={files}
              onChange={setFiles}
              label={locale.photos}
              labelAlign='top'
              name='attachmentDocs'
              accept='.png,.jpg,.jepg'
              setUploading={setUploading}
              multiple
              paste
              type='img'
            />
          </CnCol>
        </CnRow>
        <CnBox {...flexBox} justify='flex-end' spacing={8}>
          <CnButton onClick={() => cancel()}>{locale.cancel}</CnButton>
          <CnButton
            type='primary'
            disabled={uploading}
            loading={returnLoadings[item.bizId]}
            onClick={async () => {
              const values = await validateField(returnField.current);
              if (values) {
                setReturnLoadings({ [item.bizId]: true });
                operate(
                  {
                    ...values,
                    attachmentDocs: files,
                    instruction: returnField.current.getValue('instruction'),
                    node: 'CONFIRMATION',
                    businessId: item.bizId,
                    operateType: 'RETURN',
                  },
                  locale.returnProblemMsg,
                )
                  .then((success) => {
                    success &&
                      setTimeout(() => {
                        cancel();
                        reloadData();
                      }, 500);
                  })
                  .finally(() => setReturnLoadings({}));
              }
            }}
          >
            {locale.submit}
          </CnButton>
        </CnBox>
      </Form>
    </CnCard>
  );
}
