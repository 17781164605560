import { CnMessage } from '@cainiaofe/cn-ui';
import { locale, isEn, bossOrigin, assetOrigin } from './../shared';
import { useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import {
  cnRequest,
  getDataSourceLabel,
} from '../EspCommonLayout/components/utils';

export const request = (option) =>
  cnRequest({
    ...option,
    isHandle: true,
    isHandleError: true,
    withCredentials: true,
  });

// buc未登入错误码
const bossBUCWithoutLoginErrorCodeList = ['CN10201812011'];

// 菜鸟未登入错误码（需要两次encodeURIComponent）
const bossCaiNiaoWithoutLoginErrorCodeList = ['CN10201912011', '2000', '1000'];

// vision未登入错误码
const visionWithoutLoginErrorCodeList = ['CN10829902001'];

// 未登入错误码
export const withoutLoginErrorCodeList = [
  ...bossBUCWithoutLoginErrorCodeList,
  ...bossCaiNiaoWithoutLoginErrorCodeList,
  ...visionWithoutLoginErrorCodeList,
];

// boss内外部无权限错误码
export const bossWithoutAccessErrCodeList = [
  'CN10201812021',
  'CN10201812022',
  'CN10201912021',
  'CN10201912022',
];

// vision无权限错误码
export const visionWithoutAccessErrcodeList = ['CN10829902004'];

export const withoutAccessErrCodeList = [
  ...bossWithoutAccessErrCodeList,
  ...visionWithoutAccessErrcodeList,
];

// 接口异常处理
export const cnOptions = {
  loginCallback: (errorCode, errorMsg, response) => {
    // 重定向登陆
    if (withoutLoginErrorCodeList.includes(errorCode)) {
      try {
        const curUrl = bossCaiNiaoWithoutLoginErrorCodeList.includes(errorCode)
          ? encodeURIComponent(encodeURIComponent(window.location.href))
          : encodeURIComponent(window.location.href);
        const backUrl = response?.data?.extra?.backUrl || errorMsg;
        window.location.href = `${backUrl}${curUrl}`;
      } catch (error) {
        throw new Error(error);
      }
      return true;
    }
    // boss无权限跳转403
    if (
      withoutAccessErrCodeList.includes(errorCode) &&
      (window.location.origin.includes('boss') ||
        window.location.origin.includes('cnstr'))
    ) {
      window.location.href = '/403';
      return true;
    }
    // boss接口异常提示信息
    if (response?.data && !response.data.success) {
      window.openApiErrorNotification &&
        window.openApiErrorNotification(errorCode, errorMsg, response);
    }
    return false;
  },
};

export const queryLoginInfo = (setState) => {
  return request({
    url: '/menu/queryLoginInfo',
    method: 'POST',
    data: {},
  })
    .then((res) => {
      const { data, errorCode, errorMsg } = res?.data || {};
      if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
      setState && setState(data || {});
      if (window._GATEWAY_PF) {
        window._GATEWAY_PF.workerNo = `${data?.employeeId}`;
        window._GATEWAY_PF.userName = data?.employeeName;
        window._GATEWAY_PF.userId = data?.workNo;
        window._GATEWAY_PF.enterpriseId = `${data?.tenantId}`;
        window._GATEWAY_PF.loginInfo = data;
      }
      return data || {};
    })
    .catch(() => {
      setState && setState({});
    });
};

// 获取用户登陆信息
export const useUserInfo = () => {
  const [state, setState] = useState({});
  useEffect(() => {
    queryLoginInfo(setState);
  }, []);

  return state;
};

// 退出清缓存
export function logout(callback) {
  return request({
    url: '/logout',
  }).finally(callback);
}

// 获取全局菜单
export function getCurrentPcUserMenu(pinned) {
  return request({
    url: '/menu/queryUserMenu',
    method: 'post',
    data: {
      startMenuCode: 'dwork_menu',
      productCodes: [
        'SMART_PARK',
        'WAREHOUSE_BUSINESS',
        'PLATFORM',
        'ASSET',
        'BOSS',
        'VISION',
        'COST',
      ],
      fullUrl: true,
      pinned,
    },
  })
    .then((res) => {
      const { errorMsg, data, success, errorCode } = res?.data || {};
      // 未登录执行登录回调
      if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
      if (!success) {
        throw new Error(errorMsg || locale.fetchMenuErrorMessage);
      }
      if (data instanceof Array) {
        if (window.handleFormatMenuUrl) {
          window.handleFormatMenuUrl(data);
        }
        return data;
      } else {
        return [];
      }
    })
    .catch(() => []);
}

// 添加钉子
export function setPortalMenu(uri, menuCode, type) {
  return request({
    url: `/menu/${uri}`,
    method: 'POST',
    data: { menuCode },
  }).then((res) => {
    const { errorMsg, success, errorCode } = res?.data || {};
    // 未登录执行登录回调
    if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
    if (!success) {
      throw new Error(errorMsg || ` ${type}${locale.dingErrorMessage}`);
    }
    return success;
  });
}

// bucket切换
// export function switchRegion(region) {
//   return request({
//     url: `/basic/user/switch/region?region=${region}`,
//   }).finally(() => {
//     return Promise.resolve(true);
//   });
// }

// 枚举-产品模块
export const sceneEnum = [
  {
    label: locale.costMgmt,
    value: '成本管理',
  },
  {
    label: locale.siteMgmt,
    value: '工程管理',
  },
  {
    label: locale.rentMgmt,
    value: '招商租赁',
  },
  {
    label: locale.engineeringMgmt,
    value: '现场管理',
  },
];

// 枚举-状态
export const statusEnum = [
  {
    label: locale.closedStatus,
    value: 'CLOSED',
    color: 'rgba(177,185,199,1)',
    bgColor: 'rgba(177,185,199,0.25)',
  },
  {
    label: locale.rectified,
    value: 'RECTIFIED',
    color: 'rgba(0,194,103,1)',
    bgColor: 'rgba(0,194,103,0.25)',
  },
];

// 列表
export const loadList = (params) => {
  const p = JSON.parse(JSON.stringify(params));
  delete p.fecthTab;
  if (p.isConfirmed) {
    p.statusList = ['CONFIRMED'];
    p.isHandler = true;
    delete p.isConfirmed;
  }
  if (p.isAwait) {
    p.statusList = ['AWAIT', 'VERIFIED'];
    delete p.isAwait;
  }
  if (p.isRectified) {
    p.statusList = ['RECTIFIED', 'CLOSED'];
    delete p.isRectified;
  }
  return request({
    url: `${bossOrigin}/site/workOrder/problem/selectProblemList`,
    method: 'POST',
    data: p,
    loading: true,
  })
    .then((res) => {
      const { data, success, errorCode, errorMsg } = res?.data || {};
      if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
      if (success) {
        if (isEn) {
          data?.tableData?.forEach((item) => {
            const sceneName = getDataSourceLabel(
              sceneEnum,
              item.sceneName,
              'label',
            );
            if (sceneName) {
              // eslint-disable-next-line no-param-reassign
              item.sceneName = sceneName;
            }
            const statusName = getDataSourceLabel(
              statusEnum,
              item.status,
              'label',
            );
            if (statusName) {
              // eslint-disable-next-line no-param-reassign
              item.statusName = statusName;
            }
          });
        }

        return data;
      }
    })
    .catch(() => Promise.resolve({}));
};

// 新建、修改
export const update = (params) => {
  return request({
    url: `${bossOrigin}/site/workOrder/problem/saveProblem`,
    method: 'POST',
    data: params,
  }).then((res) => {
    const { data, success, errorCode, errorMsg } = res?.data || {};
    if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
    success && CnMessage.success(locale.saveProblemMsg);
    return data || success;
  });
};

// 查询-指标卡片
export const useTab = (params) => {
  const fetchRef = useRef(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const tab = useMemo(
    () => [
      {
        label: locale.toConfirm,
        value: 'isConfirmed',
        number: data.confirmCount,
      },
      {
        label: locale.solving,
        value: 'isAwait',
        number: data.unSolvedCount,
      },
      {
        label: locale.closed,
        value: 'isRectified',
        number: data.closedCount,
      },
    ],
    [data],
  );
  useEffect(() => {
    if (params.fecthTab) {
      const p = JSON.parse(JSON.stringify(params));
      delete p.statusList;
      delete p.fecthTab;
      delete p.isConfirmed;
      delete p.isAwait;
      delete p.isRectified;
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setLoading(true);
      request({
        url: `${bossOrigin}/site/workOrder/problem/queryProblemCount`,
        method: 'POST',
        data: p,
      })
        .then((res) => {
          const { data: obj, errorCode, errorMsg } = res?.data || {};
          if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
          if (fetchId === fetchRef.current) {
            setData(obj || {});
          }
        })
        .catch(() => {
          if (fetchId === fetchRef.current) {
            setData({});
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [params]);
  return {
    tab,
    loading,
    initValues: {
      isConfirmed: undefined,
      isAwait: undefined,
      isRectified: undefined,
    },
  };
};

// 查询-固定项目BizId
export const useProjectBizId = (load) => {
  const fetchRef = useRef(0);
  const [projectBizId, setProjectBizId] = useState();
  useEffect(() => {
    if (load) {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      request({
        url: `${bossOrigin}/basic/buildProject/getItSupportProjectBizId`,
        method: 'GET',
        data: {},
      })
        .then((res) => {
          const { data, errorCode, errorMsg } = res?.data || {};
          if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
          if (fetchId === fetchRef.current) {
            setProjectBizId(data);
          }
        })
        .catch(() => {
          if (fetchId === fetchRef.current) {
            setProjectBizId();
          }
        });
    }
  }, [load]);
  return projectBizId;
};

// 查询-全部状态的业务类型档案
export const useSceneBizId = (noBoss, currentPageProductCode) => {
  const fetchRef = useRef(0);
  const [sceneBizId, setSceneBizId] = useState();
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    request({
      url: `${bossOrigin}/site/workOrder/base/getSceneList`,
      method: 'POST',
      data: {
        pageSize: 2000,
        status: 'ENABLED',
        productSkillStatus: true,
      },
    })
      .then((res) => {
        const { data, errorCode, errorMsg } = res?.data || {};
        if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
        if (fetchId === fetchRef.current) {
          const tableData = data?.tableData;
          // 根据产品名称获取业务类型bizId
          const getSceneBizId = (name) =>
            tableData?.find((item) => item.name.includes(name))?.bizId;
          let bizId;
          if (currentPageProductCode) {
            bizId = tableData.find((item) =>
              item.productDomainCodeList?.includes(currentPageProductCode),
            )?.bizId;
          }
          if (!bizId) {
            if (!noBoss) {
              if (
                window.location.pathname.includes('/cost') ||
                window.location.pathname.includes('/operation')
              ) {
                bizId = getSceneBizId('成本管理');
              } else if (window.location.pathname.includes('/rent')) {
                bizId = getSceneBizId('招商租赁');
              } else if (window.location.pathname.includes('/asset')) {
                bizId = getSceneBizId('资产管理/Nirvana');
              }
            } else if (!window.location.origin.includes('park')) {
              bizId = getSceneBizId('幻视/Vision');
            } else if (window.location.href.includes('asset')) {
              bizId = getSceneBizId('资产管理/Nirvana');
            } else {
              bizId = getSceneBizId('园区管理') || getSceneBizId('智慧园区');
            }
          }
          if (!bizId) {
            bizId = getSceneBizId('开发建设') || tableData?.[0]?.bizId;
          }
          setSceneBizId(bizId);
          setDataSource(
            data?.tableData?.map((item) => {
              return {
                ...item,
                label: item.name,
                value: item.bizId,
              };
            }) || [],
          );
        }
      })
      .catch(() => {
        if (fetchId === fetchRef.current) {
          setSceneBizId();
          setDataSource([]);
        }
      });
  }, [noBoss, currentPageProductCode]);
  return [dataSource, sceneBizId];
};

// 查询-业务类型下第一个专业的bizId
export const useMajorBizId = (sceneBizId) => {
  const fetchRef = useRef(0);
  const [majorBizId, setMajorBizId] = useState();
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    if (sceneBizId) {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      request({
        url: `${bossOrigin}/site/workOrder/base/getMajorListByScene`,
        method: 'POST',
        data: {
          sceneBizId,
          productSkillStatus: true,
        },
      })
        .then((res) => {
          const { data, errorCode, errorMsg } = res?.data || {};
          if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
          if (fetchId === fetchRef.current) {
            // 根据问题类型名称获取问题类型bizId
            const getMajorBizId = (name) =>
              data?.find((item) => item.name.includes(name))?.bizId;
            // 默认专业取运营类
            const bizId = getMajorBizId('运营类') || data?.[0]?.bizId;
            if (bizId) {
              setMajorBizId(bizId);
            }
            setDataSource(
              data?.map((item) => {
                return {
                  ...item,
                  label: item.name,
                  value: item.bizId,
                };
              }) || [],
            );
          }
        })
        .catch(() => {
          if (fetchId === fetchRef.current) {
            setMajorBizId();
            setDataSource([]);
          }
        });
    } else {
      setMajorBizId();
      setDataSource([]);
    }
  }, [sceneBizId]);
  return [dataSource, majorBizId];
};

// 查询-处理流程
export const useNodeDTOList = (useNode, scene, major) => {
  const fetchRef = useRef(0);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (scene && major) {
      if (useNode) {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setLoading(true);
        request({
          url: `${bossOrigin}/site/workOrder/base/getFlowNodeByOneUser`,
          method: 'POST',
          data: { scene, major },
        })
          .then((res) => {
            const { data, errorCode, errorMsg } = res?.data || {};
            if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
            if (fetchId === fetchRef.current) {
              data?.forEach((item) => {
                // const currentOperator =
                //   index === data.length - 1
                //     ? Number(window?._GATEWAY_PF?.workerNo)
                //     : undefined;
                // const currentOperatorName =
                //   index === data.length - 1
                //     ? window?._GATEWAY_PF?.userName
                //     : undefined;
                // eslint-disable-next-line no-param-reassign
                item.operator = item.userId || item.operator;
                // eslint-disable-next-line no-param-reassign
                item.operatorName = item.userName || item.operatorName;
              });
              setDataSource(data || []);
            }
          })
          .catch(() => {
            if (fetchId === fetchRef.current) {
              setDataSource([]);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setDataSource([]);
    }
  }, [useNode, scene, major]);
  return [dataSource, loading];
};

// 操作
export const operate = (params, msg) => {
  return request({
    url: `${bossOrigin}/site/workOrder/flow/operate`,
    method: 'POST',
    data: params,
  }).then((res) => {
    const { data, errorCode, errorMsg, success } = res?.data || {};
    if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
    success && CnMessage.success(msg);
    return data || success;
  });
};

// 钉当前处理人
export const dingCurrentPerson = (params) => {
  return request({
    url: `${bossOrigin}/site/workOrder/problem/dingCurrentPerson`,
    method: 'POST',
    data: params,
  }).then((res) => {
    const { data, errorCode, errorMsg, success } = res?.data || {};
    if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
    success && CnMessage.success(locale.dingProblemMsg);
    return data || success;
  });
};

// 关闭
export const close = (params) => {
  return request({
    url: `${bossOrigin}/site/workOrder/flow/close`,
    method: 'POST',
    data: params,
  }).then((res) => {
    const { data, errorCode, errorMsg, success } = res?.data || {};
    if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
    success && CnMessage.success(locale.closeProblemMsg);
    return data || success;
  });
};

// 重开
export const reApply = (params) => {
  return request({
    url: `${bossOrigin}/site/workOrder/flow/reApply`,
    method: 'POST',
    data: params,
  }).then((res) => {
    const { data, errorCode, errorMsg, success } = res?.data || {};
    if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
    success && CnMessage.success(locale.reApplyProblemMsg);
    return data || success;
  });
};

// 获取ticket
export const getSSOTicket = () => {
  return request({
    url: `/account/buc/getSSOTicket`,
    method: 'POST',
  }).then((res) => {
    const { errorCode, errorMsg, msg, data } = res?.data || {};
    if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
    return msg || data;
  });
};

// boss设置ticket
export const setSSOTicket = (SSO_TICKET) => {
  return request({
    url: `${bossOrigin}/buc/getSSOTicket?SSO_TICKET=${SSO_TICKET}`,
    method: 'POST',
    data: { SSO_TICKET },
  }).then((res) => {
    const { errorCode, errorMsg } = res?.data || {};
    if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
  });
};

// boss获取ticket
export const bossGetSSOTicket = () => {
  return request({
    url: `/buc/SSOTicket`,
    method: 'POST',
  }).then((res) => {
    const { errorCode, errorMsg, msg, data } = res?.data || {};
    if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
    return msg || data;
  });
};

// boss设置asset-ticket
export const bossSetAssetSSOTicket = (SSO_TICKET) => {
  return request({
    url: `${assetOrigin}/buc/setSSOTicket?SSO_TICKET=${SSO_TICKET}`,
    method: 'POST',
    data: { SSO_TICKET },
  }).then((res) => {
    const { errorCode, errorMsg } = res?.data || {};
    if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
  });
};

// asset获取ticket
export const assetGetSSOTicket = () => {
  return request({
    url: `/buc/getSSOTicket`,
    method: 'POST',
  }).then((res) => {
    const { errorCode, errorMsg, msg, data } = res?.data || {};
    if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
    return msg || data;
  });
};

// 待办列表
export const getTodoList = () => {
  return request({
    url: `${bossOrigin}/basic/todo/queryPage`,
    method: 'POST',
    data: {
      currentPage: 1,
      pageSize: 1000,
      tabCode: 'MY_TODO',
    },
  }).then((res) => {
    const { errorCode, errorMsg, data } = res?.data || {};
    if (cnOptions.loginCallback(errorCode, errorMsg, res)) return null;
    return data?.tableData || [];
  });
};
