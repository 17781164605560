/* eslint-disable react/no-array-index-key */
import { css, styled } from 'styled-components';
import { CnTooltip, CnInput, CnMessage, CnTab, CnTabItem, } from '@cainiaofe/cn-ui';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { RequestWrapper } from '../../widgets/RequestWrapper';
import { WorkbenchService } from '../../services/WorkbenchService';
import { searchResTypeMap } from '../../utils/map';
// import { WinTab } from './WinTab';
import { handleOpenLink } from '../../utils/util';
// import { IndicatorCard } from './IndicatorCard';
import { SearchType } from '../../utils/enum';
import { baseFlex } from '../../styles/css';
export const SearchBar = ({ conversationId, placeholder, search, setSearch, }) => {
    const [searchResType, setSearchResType] = useState(searchResTypeMap);
    useEffect(() => {
        (async () => {
            const list = await WorkbenchService.getSearchResType();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            list?.forEach(({ label, value }) => {
                searchResTypeMap.set(value, { label });
            });
            setSearchResType(new Map(searchResTypeMap));
        })();
    }, []);
    const [searchType, setSearchType] = useState(SearchType.NONE);
    const [input, setInput] = useState('');
    // 聚焦
    const handleFocus = () => {
        setSearchType(SearchType.HOT);
    };
    // 文本输入
    const handleChange = (value) => {
        // setSearchType(SearchType.RECOMMEND);
        setInput(value);
    };
    // 查询
    const handSearch = (key) => {
        setInput(key);
        setSearchType(SearchType.RESULT);
        if (key === input && searchType === SearchType.RESULT) {
            setSearch(false);
        }
    };
    // 无内容回归热词推荐
    useEffect(() => {
        if (!input && searchType !== SearchType.NONE) {
            setSearchType(SearchType.HOT);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input]);
    // 收起结果框
    useEffect(() => {
        setSearchType(SearchType.NONE);
    }, [conversationId]);
    const isMobile = useMemo(() => !!document.querySelector('.PortalCommonHeader.mobile'), []);
    return (React.createElement(Wrapper, { onClick: (e) => e.stopPropagation() },
        search ? (React.createElement(CnInputWrapper, { hasBorder: false, placeholder: placeholder, value: input, onChange: handleChange, onFocus: handleFocus, onPressEnter: () => handSearch(input) })) : null,
        React.createElement("img", { src: 'https://img.alicdn.com/imgextra/i2/O1CN01ZabfU41TZPrjpQjan_!!6000000002396-55-tps-16-16.svg', alt: placeholder, onClick: () => handSearch(input) }),
        React.createElement(SearchDropWrapper, { "$show": searchType !== SearchType.NONE, "$isMobile": isMobile },
            searchType === SearchType.HOT && !input && (React.createElement(RequestWrapper, { service: WorkbenchService.getHotDataList }, (data) => {
                const renderLabel = (list = []) => list.map((item, index) => (React.createElement("span", { key: index, "data-label": true, onClick: () => handSearch(item.word) }, item.word)));
                return (React.createElement(HotAndRecentWrapper, null,
                    React.createElement("div", { style: { whiteSpace: 'pre-wrap' } },
                        React.createElement(ResultTitle, null, "\u70ED\u8BCD"),
                        renderLabel(data?.hotWords)),
                    React.createElement("div", { style: { whiteSpace: 'pre-wrap' } },
                        React.createElement(ResultTitle, null, "\u6700\u8FD1\u641C\u7D22"),
                        renderLabel(data?.recentWords))));
            })),
            searchType === SearchType.RECOMMEND && !!input && (React.createElement(RequestWrapper, { debounceWait: 500, requestData: { keyWord: input }, service: WorkbenchService.getRecommendDataList }, (data) => (React.createElement(RecommendWrapper, null, data?.map((item) => (React.createElement("div", { onClick: () => handSearch(item.word) }, item.word))))))),
            searchType === SearchType.RESULT && (React.createElement(RequestWrapper, { requestData: { keyWord: input }, service: WorkbenchService.getSearchDataList }, (data) => (React.createElement(SearchInfoList
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            , { 
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                data: data, keyWord: input, searchResType: searchResType, isMobile: isMobile })))))));
};
const Wrapper = styled.div `
  width: 210px;
  height: 32px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
`;
const CnInputWrapper = styled(CnInput) `
  background-color: transparent !important;
  input,
  input::placeholder {
    color: #fff !important;
    font-size: 14px !important;
  }
  input {
    padding: 6px 8px !important;
  }
`;
const SearchDropWrapper = styled.div `
  min-width: 540px;
  max-width: 540px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0px rgba(39, 46, 58, 0.12);
  position: fixed;
  right: 8px;
  top: 72px;
  z-index: 10;

  ${(props) => !props.$show &&
    css `
      display: none;
    `}

  ${(props) => props.$isMobile &&
    css `
      min-width: 100vw;
      max-width: 100vw;
      right: 0;
    `}
`;
const ResultTitle = styled.div `
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 12px;
`;
const HotAndRecentWrapper = styled.div `
  padding: 20px;
  display: grid;
  gap: 16px;
  line-height: 1;

  [data-label] {
    display: inline-block;
    padding: 4px 16px;
    background-color: #eaf1fe;
    margin-right: 12px;
    border-radius: 500px;
    margin-bottom: 8px;
    cursor: pointer;
  }
`;
const RecommendWrapper = styled.div `
  padding: 20px;
  display: grid;
  gap: 12px;
  font-size: 14px;
  font-weight: 700;

  div {
    cursor: pointer;
  }
`;
const SearchInfoList = ({ data = [], keyWord, searchResType, isMobile, }) => {
    const [curTab, setCurTab] = useState('ALL');
    const isAll = curTab === 'ALL';
    const tabs = useMemo(() => [
        { label: '全部', value: 'ALL' },
        ...data.map((item) => ({
            label: `${searchResType.get(item.type)?.label}（${item.count}）`,
            value: item.type,
        })),
    ], [data, searchResType]);
    const allList = data?.filter((item) => item.list.length > 0) ?? [];
    return (React.createElement(ResultWrapper, null,
        React.createElement(CnTab, { shape: 'pure', activeKey: curTab, onChange: (key) => setCurTab(key), style: {
                marginTop: -12,
                marginBottom: 12,
                maxWidth: isMobile ? 'calc(100vw - 40px)' : 500,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                '--tab-pure-text-color-normal': '#394252',
            }, smartPadding: false }, tabs.map((item) => (React.createElement(CnTabItem, { title: item.label, key: item.value })))),
        React.createElement(ResultMsgWrapper, null, isAll
            ? allList.map((item, index) => {
                const { label } = searchResType.get(item.type) ?? {};
                return (React.createElement("div", { key: index, style: { marginBottom: 20 } },
                    React.createElement(ResultTitle, null, `${label}（${item.count}）`),
                    React.createElement(ResultItemList, { data: item.list.slice(0, item.type === 'CARD' ? 2 : 3) })));
            })
            : allList.map((item, index) => (React.createElement(TypeResult, { key: index, ...{
                    keyWord,
                    type: item.type,
                    show: item.type === curTab,
                } }))))));
};
const ResultItemList = ({ data }) => {
    return (React.createElement(ResultListWrapper, null, data.map((ele, key) => {
        return !!ele.ext && ele.ext.type === 'CARD' ? (React.createElement("div", { key: key },
            React.createElement("div", { style: {
                    marginBottom: 12,
                } }, ele.title),
            React.createElement("div", null, "\u6307\u6807\u56FE\u8868\u5361\u7247\u6682\u4E0D\u652F\u6301\u663E\u793A"))) : (React.createElement(ResultItem, { key: key, trigger: React.createElement(ResultLabel, { onClick: () => handleOpenLink(ele.url), style: {
                    gridColumn: '1 / span 2',
                    width: 'max-content',
                } }, ele.title) }, ele.content));
    })));
};
const TypeResult = ({ keyWord, type, show, }) => {
    const [isInitData, setIsInitData] = useState(false);
    const ref = useRef(null);
    const pageRef = useRef({ page: 0, total: 0, curDataTotal: 0 });
    const [resultDataList, setResultDataList] = useState([]);
    const [dataMsg, setDataMsg] = useState();
    const handleGetData = async () => {
        const { page, total, curDataTotal } = pageRef.current;
        if (page === 0 || total > curDataTotal) {
            try {
                setDataMsg('加载中...');
                const resp = await WorkbenchService.getSearchDataListByType({
                    keyWord,
                    type,
                    pageSize: 20,
                    page: page + 1,
                });
                setResultDataList((state) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const newData = [...state, ...resp.tableData];
                    pageRef.current = {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        page: resp.paging.currentPage,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        total: resp.paging.totalCount,
                        curDataTotal: newData.length,
                    };
                    return newData;
                });
            }
            catch (error) {
                CnMessage.error(error.message);
            }
            finally {
                setDataMsg(undefined);
            }
        }
        else {
            setDataMsg('没有更多了...');
        }
    };
    useEffect(() => {
        if (!isInitData && show) {
            handleGetData();
            setIsInitData(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitData, show]);
    useEffect(() => {
        const element = ref.current;
        element.addEventListener('scroll', () => {
            const { scrollHeight } = element;
            const { scrollTop } = element;
            const { clientHeight } = element;
            if (scrollTop + clientHeight >= scrollHeight - 1) {
                // 触底
                handleGetData();
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement("div", { ref: ref, style: {
            maxHeight: 400,
            overflow: 'auto',
            ...(!show && {
                display: 'none',
            }),
        } },
        React.createElement(ResultItemList, { data: resultDataList }),
        React.createElement("p", { style: {
                ...baseFlex,
                height: 24,
            } }, dataMsg)));
};
const ResultWrapper = styled.div `
  padding: 20px;
  display: grid;
  gap: 16px;
`;
const ResultMsgWrapper = styled.div `
  max-height: 500px;
  min-height: 60px;
  overflow: auto;
`;
const ResultListWrapper = styled.div `
  display: grid;
  gap: 16px;
  grid: auto-flow / repeat(2, 1fr);
  padding-bottom: 12px;
`;
const ResultItem = styled(CnTooltip).attrs({
    v2: true,
    align: 'bl',
    popupStyle: {
        maxHeight: 400,
        overflow: 'auto',
    },
}) `
  white-space: pre-wrap;
`;
const ResultLabel = styled.div `
  cursor: pointer;
`;
