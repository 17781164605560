/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import EspCommonLayout, { isMobile } from './EspCommonLayout';
import {
  CnBox,
  CnTooltip,
  Notification,
  Dialog,
  CnShell,
  CnButton,
  CnIcon,
} from '@cainiaofe/cn-ui';
import { getRadomStr } from '../utils/util';
import {
  moziLogout,
  apiBaseUrlLogin,
  getCurrentActiveByMenuCode,
  getShowSideBarByCode,
  filterTreeGetMenu,
  locale,
  isEn,
  copyText,
  useLanguage,
  winOrigin,
  PARK_API,
  redirectURL,
} from './shared';
import { MAX_PINNED_LENGTH } from './shared/const';
import {
  getCurrentPcUserMenu,
  setPortalMenu,
  logout,
  getSSOTicket,
  setSSOTicket,
  // bossGetSSOTicket,
  // bossSetAssetSSOTicket,
  assetGetSSOTicket,
} from './services';
import { PinDialog } from './components/PinDialog';
import { longTextRender } from './EspCommonLayout/components/utils';
import List from './EspCommonLayout/components/list';
import './index.scss';

const workbenchMenuCode = 'MY_VISION';
const sideBarMenuCodeList = [
  'DWORK_CNSTR_GCGL_TASK',
  'DWORK_CNSTR_COST_CALCULATE',
];

export default function PortalCommonHeader(props) {
  const {
    noBoss = false,
    useHelp = false,
    downloadCenterUrl = '',
    children,
  } = props;

  // 获取登录信息
  const userInfo = useMemo(
    () =>
      window._GATEWAY_PF?.loginInfo || {
        employeeName: window._GATEWAY_PF?.userName,
        workNo: window._GATEWAY_PF?.userId,
        employeeId: window._GATEWAY_PF?.workerNo,
        employeeType:
          window?._GATEWAY_PF?.enterpriseId === '25' ||
          window?._GATEWAY_PF?.enterpriseId === '3'
            ? 'inside'
            : 'outside',
      },
    [window._GATEWAY_PF],
  );

  // 多语言
  const language = useLanguage();

  const [visible, setVisible] = useState(false);

  const ExtraRender = (
    <CnBox
      direction='row'
      style={{ marginLeft: 'auto', height: 64 }}
      align='center'
      className='view-header view-header-isTNav'
    >
      {/* 登录按钮 */}
      {!userInfo.employeeName && (
        <CnBox direction='row' style={{ marginLeft: 'auto' }} align='center'>
          <span
            className='menu-login no-select'
            onClick={() => setVisible(true)}
          >
            {locale.login}
          </span>
        </CnBox>
      )}
      {/* 用户信息 */}
      {userInfo.employeeName && (
        <CnBox direction='row' style={{ marginLeft: 'auto' }} align='center'>
          <CnTooltip
            triggerType='click'
            className='tooltip-header'
            trigger={
              <CnBox className='user-icon' justify='center' align='center'>
                <CnBox
                  className='user-icon-inner no-select'
                  justify='center'
                  // align='center'
                >
                  <span style={{ fontSize: 12 }}>{userInfo.employeeName}</span>
                </CnBox>
              </CnBox>
            }
            align='bl'
            offset={[20, 20]}
            popupContainer={(trigger) => trigger.parentNode}
          >
            <div style={{ display: 'flex', color: '#000' }}>
              <div className='tooltip-header-name'>
                <span>{userInfo.employeeName || '-'}</span>
              </div>
              <div className='tooltip-header-title'>
                <div className='title'>{userInfo.employeeName || '-'}</div>
                <CnBox
                  className='tooltip-header-title-username'
                  direction='row'
                  align='center'
                >
                  <span>
                    {locale.workerNo}
                    {userInfo.workNo || userInfo.employeeId || '-'}
                  </span>
                </CnBox>
                <CnBox
                  className='tooltip-header-title-exit'
                  justify='center'
                  align='center'
                  onClick={() => (noBoss ? moziLogout() : logout(moziLogout))}
                >
                  {locale.loginOut}
                </CnBox>
              </div>
            </div>
          </CnTooltip>
        </CnBox>
      )}
    </CnBox>
  );

  // 树结构添加key-level
  function arrangeData(oldEditData = [], newEditData = [], level, isLeft) {
    for (let i = 0; i < oldEditData.length; i++) {
      const key = `${level}-${i}`;
      const ele = oldEditData[i];
      const isWorkbench = workbenchMenuCode === ele.menuCode;
      if (isEn && ele.engName) {
        ele.menuTitle = ele.engName;
      }
      newEditData.push({
        ...ele,
        key: isLeft ? ele.menuCode : key,
        // isDing: !isWorkbench && key.split('-').length < 4,
        menuUrl: ele.menuUrl ? ele.menuUrl : undefined,
        isDing: !isWorkbench && ele.children?.length,
        icon: ele.menuIcon,
        text: ele.menuTitle,
      });
      if (Array.isArray(ele.children) && ele.children.length) {
        newEditData[i].children = [];
        arrangeData(
          oldEditData[i].children,
          newEditData[i].children,
          key,
          isLeft,
        );
      }
    }
  }

  function getItemsFromTree(fromData, returnData) {
    fromData.forEach((item) => {
      if (
        item.pinned ||
        returnData.map(({ menuCode }) => menuCode).includes(item.menuCode)
      ) {
        const index = returnData.findIndex(
          (ele) => item.menuCode === ele.menuCode,
        );
        if (index !== -1) {
          returnData[index] = item;
        }
      }
      if (item.children && item.children.length) {
        getItemsFromTree(item.children, returnData);
      }
    });
  }

  const [globalMenuVisible, setGlobalMenuVisible] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const defaultWorkbenchMenu = {
    menuType: 'MENU',
    menuTitle: locale.workbench,
    menuUrl: `${winOrigin}${window.dworkPathnameMap.vision}/workbench/portal?code=ROLE_PC`,
    menuCode: workbenchMenuCode,
  };
  const [customMenu, setCustomMenu] = useState([defaultWorkbenchMenu]);

  const initMenu = () => {
    // 初始化获取导航菜单及用户钉住的菜单
    Promise.all([getCurrentPcUserMenu(), getCurrentPcUserMenu(true)])
      .then((res) => {
        // 钉住的菜单
        const curMenu = res[1] || [];
        // 是否有My_Vision权限并钉住
        const myIndex = curMenu.findIndex(
          (item) => item.menuCode === defaultWorkbenchMenu.menuCode,
        );
        if (myIndex === -1) {
          // 无-前端添加默认
          curMenu.unshift(defaultWorkbenchMenu);
        } else if (myIndex > 0) {
          // 有-但不是第一个，前端移动到第一个
          const myVision = curMenu[myIndex];
          curMenu.splice(myIndex, 1);
          curMenu.unshift(myVision);
        }
        if (res[0].length) {
          const newMenuList = [];
          // 遍历菜单增加层级、是否可钉及左侧菜单（boss任务协同及成本）相关属性
          arrangeData(res[0], newMenuList, 0);
          setMenuList(newMenuList);
          // 去全部菜单中去获取钉住的菜单子集（My_Vision未默认钉住时）
          getItemsFromTree(newMenuList, curMenu);
          // 获取当前页面菜单menuCode并透传（boss上传组件需要知道上传页面来源
          getCurrentActiveByMenuCode(newMenuList);
        }
        // 如果只有一个子集工作台，移动到My Vision
        if (
          curMenu?.[0]?.children?.length === 1 &&
          curMenu?.[0]?.children?.[0]?.menuUrl
        ) {
          curMenu[0].menuUrl = curMenu[0].children[0].menuUrl;
          delete curMenu[0].children;
        }
        // 由于getItemsFromTree，可能会去掉默认的defaultWorkbenchMenu.menuUrl，判断加入
        if (curMenu[0] && !curMenu[0].children?.length && !curMenu[0].menuUrl) {
          curMenu[0].menuUrl = defaultWorkbenchMenu.menuUrl;
        }
        // 透传默认vision工作台（首页显示用）
        window.MY_VISION_MENU_URL =
          curMenu?.[0]?.children?.[0]?.menuUrl ||
          curMenu?.[0]?.menuUrl ||
          defaultWorkbenchMenu.menuUrl;
        window.MENU_LOAD = true;
        setCustomMenu(curMenu);
      })
      .catch(() => {
        // 接口失败，跳转默认工作台
        window.MY_VISION_MENU_URL = defaultWorkbenchMenu.menuUrl;
        window.MENU_LOAD = true;
      });
  };

  useEffect(() => {
    initMenu();
  }, []);

  const [customMenuVisible, setCustomMenuVisible] = useState({});

  function linkTo(item, e) {
    if (item.menuUrl) {
      // window.location.href = item.menuUrl;
      setGlobalMenuVisible(false);
      redirectURL(item.menuUrl, false, setCustomMenuVisible, e);
    }
  }

  const fetchRef = useRef(false);

  const filterFormatMoreCustomMenu = useMemo(() => {
    const isInside = userInfo.employeeType === 'inside';
    const formatCustomMenu = JSON.parse(JSON.stringify(customMenu));
    // boss外部用户，去掉My Vision跳转
    if (!noBoss && userInfo.employeeType && !isInside) {
      formatCustomMenu[0].menuUrl = '';
    }
    return filterTreeGetMenu(formatCustomMenu);
  }, [customMenu, userInfo]);
  const canPin = filterFormatMoreCustomMenu.length < MAX_PINNED_LENGTH + 1;
  const [showDialog, setShowDialog] = useState(false);
  const [operateItem, setOperateItem] = useState();
  const handleCloseDialog = () => {
    setShowDialog(false);
    setOperateItem(undefined);
  };
  // 处理钉
  async function handlePinned(item, selected) {
    const fetchId = fetchRef.current;
    if (fetchId) {
      Notification.open({
        title: locale.fetchErrorMessage,
        duration: 2000,
      });
      return;
    }
    if (selected) {
      fetchRef.current = true;
      const customMenus = customMenu.concat([{ ...item }]);
      customMenus.splice(0, 1);
      setPortalMenu('pinMenu', item.menuCode, locale.add).then((res) => {
        if (res) {
          initMenu();
          // item.pinned = selected;
          // setCustomMenu(customMenu.concat([{ ...item }]));
        }
        setTimeout(() => {
          fetchRef.current = false;
        }, 0);
      });
    } else {
      // 删除
      fetchRef.current = true;
      const customMenus = customMenu.filter((it) => it.key !== item.key);
      customMenus.splice(0, 1);
      setPortalMenu('unPinMenu', item.menuCode, locale.delete).then((res) => {
        if (res) {
          initMenu();
          // item.pinned = selected;
          // setCustomMenu(customMenu.filter((it) => it.key !== item.key));
        }
        setTimeout(() => {
          fetchRef.current = false;
        }, 0);
      });
    }
  }
  // 查验钉
  const handleCheckPinned = (item, selected) => {
    if (!canPin && selected) {
      setOperateItem(item);
      setShowDialog(true);
    } else {
      handlePinned(item, selected);
    }
  };

  if (
    'onhashchange' in window &&
    (typeof document.documentMode === 'undefined' ||
      document.documentMode === 8)
  ) {
    // 浏览器支持onhashchange事件
    window.onhashchange = () => {
      setCustomMenu([...customMenu]); // TODO，对应新的hash执行的操作函数
      getCurrentActiveByMenuCode(menuList);
    };
  }
  const [isGetIntoPortal, setIsGetIntoPortal] = useState(
    'noFirstGetIntoPortal',
    // window.localStorage.getItem('isGetIntoPortal'),
  );

  const [currentPageMenuCode, setCurrentPageMenuCode] = useState(
    window.MENUCODE,
  );
  const [currentPageProductCodeList, setCurrentPageProductCodeList] = useState(
    window.productCodeList,
  );
  const [curItem, setCurItem] = useState({});

  useEffect(() => {
    const curItems = [];
    getCurrentActiveByMenuCode(menuList, curItems);
    if (curItems[0]) {
      setCurrentPageMenuCode(curItems[0].menuCode);
      setCurrentPageProductCodeList(curItems[0].productCodeList);
      setCurItem(curItems[0]);
    }
  }, [menuList, customMenu]);
  const [sideBarMenu, setSideBarMenu] = useState([]);
  useEffect(() => {
    if (!currentPageMenuCode) {
      setSideBarMenu([]);
      return;
    }
    const { sideBarMenuCode, currentChild } = getShowSideBarByCode(
      currentPageMenuCode,
      sideBarMenuCodeList,
      menuList,
    );
    if (sideBarMenuCode) {
      const newMenuList = [];
      arrangeData(currentChild, newMenuList, 0, true);
      setSideBarMenu(newMenuList);
    } else {
      setSideBarMenu([]);
    }
  }, [currentPageMenuCode]);
  // 过滤掉按钮和页面 只显示菜单
  const filterMenuList = useMemo(
    filterTreeGetMenu.bind(
      this,
      menuList.filter((item) => item.menuCode !== workbenchMenuCode),
    ),
    [menuList],
  );
  const filterSideBarMenu = useMemo(filterTreeGetMenu.bind(this, sideBarMenu), [
    sideBarMenu,
  ]);

  // 显示工单列表
  const [showWorkOrder, setShowWorkOrder] = useState(
    window.location.search.includes('showWorkOrder=true'),
  );
  const [listVisible, setListVisible] = useState(showWorkOrder);
  window.openWorkOrderDrawer = () => {
    setShowWorkOrder(true);
    setListVisible(true);
  };

  // 配置-可手动调用显示
  const [helpVisible, setHelpVisible] = useState(
    window.location.search.includes('showHelp=true'),
  );

  // park资产白名单
  const parkAssetOpenCodeList = [
    'CN102101400',
    'CN102101403',
    'CN102101404',
    'CN102101409',
    'CN102101500',
    'CN10210140001',
    'CN10210110001',
    'CN10210110002',
    'CN10210110003',
    'CN10210110004',
    'CN10210110101',
    'CN10210110102',
    'CN10210110103',
    'CN10210110110',
    'CN10210110111',
    'CN10210110112',
    'CN10210110113',
    'CN10210110114',
    'CN10210110115',
    'CN10210110116',
    'CN10210110117',
    'CN10210110118',
  ];
  // park其他白名单
  const parkOtherOpenCodeList = ['1006', 'CN10225210000', 'CN102252DWORK10000'];
  // park白名单
  const parkOpenCodeList = parkOtherOpenCodeList.concat(parkAssetOpenCodeList);

  // boss接口出错，打开帮助
  const openApiErrorNotification = (errorCode, errorMsg, response) => {
    if (
      (noBoss && !useHelp) ||
      (window.location.origin.includes('park') &&
        !parkOpenCodeList.includes(errorCode))
    ) {
      return;
    }
    const traceId = response?.headers?.['eagleeye-traceid'];
    if (Array.isArray(window.apiErrorTraceIds)) {
      if (window.apiErrorTraceIds.includes(traceId)) {
        return;
      }
      window.apiErrorTraceIds.push(traceId);
    } else {
      window.apiErrorTraceIds = [traceId];
    }
    Notification.open({
      duration: 60000,
      type: 'help',
      title: (
        <CnBox>
          <CnBox style={{ fontSize: 20 }}>{locale.apiErrorTitle}</CnBox>
          <CnBox style={{ marginTop: 8 }}>{longTextRender(errorMsg)}</CnBox>
        </CnBox>
      ),
      content: (
        <CnBox>
          <CnBox direction='row'>
            <div>{locale.apiErrorCode}</div>
            <div>{errorCode}</div>
          </CnBox>
          <CnBox direction='row' align='center'>
            <div>{locale.apiErrorTraceID}</div>
            <div>{traceId}</div>
            <CnButton
              text
              type='primary'
              style={{ marginLeft: 2 }}
              onClick={() => copyText(traceId, locale.copyApiErrorTraceID)}
            >
              <CnIcon type='copy' size='medium' />
            </CnButton>
          </CnBox>
          <CnBox direction='row' justify='flex-end' style={{ marginTop: 8 }}>
            <CnButton
              type='secondary'
              onClick={() => {
                setHelpVisible(window.apiErrorTraceIds);
                window.apiErrorTraceIds = [];
                Notification.destroy();
              }}
            >
              <CnIcon type='help' />
              {locale.helpEstateAsst}
            </CnButton>
          </CnBox>
        </CnBox>
      ),
      onClose: () => {
        const ids = window.apiErrorTraceIds || [];
        const i = ids.findIndex((item) => item === traceId);
        if (i !== -1) {
          window.apiErrorTraceIds.splice(i, 1);
        }
      },
    });
  };

  window.openApiErrorNotification = openApiErrorNotification;
  window.openHelpDrawer = (apiErrorTraceIds) =>
    setHelpVisible(apiErrorTraceIds || []);

  // 导航隐藏，卸载弹消息方法
  useEffect(() => {
    return () => {
      delete window.openApiErrorNotification;
      delete window.openHelpDrawer;
    };
  }, []);

  const [search, setSearch] = useState(false);
  const [searchConversationId, setSearchConversation] = useState(getRadomStr());
  useEffect(() => {
    if (currentPageMenuCode) {
      const { sideBarMenuCode } = getShowSideBarByCode(
        currentPageMenuCode,
        ['MY_VISION'],
        menuList,
      );
      setSearch(!sideBarMenuCode && !isMobile);
    }
  }, [currentPageMenuCode]);

  // win-buc和vision-buc需要获取boss登录态
  useEffect(() => {
    if (noBoss && window.location.origin.includes('-inc.com')) {
      getSSOTicket().then((SSO_TICKET) => {
        SSO_TICKET && setSSOTicket(SSO_TICKET);
      });
    }
  }, []);

  // boss-buc需要获取asset登录态
  // useEffect(() => {
  //   if (
  //     !noBoss &&
  //     !window.location.origin.includes('asset') &&
  //     window.location.pathname.includes('basic') &&
  //     window.location.hash.includes('approve') &&
  //     (window.location.origin.includes('-inc.com') ||
  //       window.location.origin.includes('-staff.cainiao.test'))
  //   ) {
  //     bossGetSSOTicket().then((SSO_TICKET) => {
  //       SSO_TICKET && bossSetAssetSSOTicket(SSO_TICKET);
  //     });
  //   }
  // }, []);

  const [loadToDo, setLoadToDo] = useState(false);
  // asset-buc需要获取boss登录态
  useEffect(() => {
    if (
      !noBoss &&
      window.location.origin.includes('asset') &&
      (window.location.origin.includes('-inc.com') ||
        window.location.origin.includes('-asset.cainiao.test'))
    ) {
      assetGetSSOTicket().then((SSO_TICKET) => {
        SSO_TICKET && setSSOTicket(SSO_TICKET).finally(() => setLoadToDo(true));
      });
    } else {
      setLoadToDo(true);
    }
  }, []);

  return (
    <div
      className='win cn-shell-have-set-height'
      onClick={() => setSearchConversation(getRadomStr())}
    >
      <div style={{ height: 64 }} />
      <div
        id='PortalCommonHeader'
        className={`PortalCommonHeader ${isMobile && 'mobile'}`}
      >
        <EspCommonLayout
          globalMenuVisible={globalMenuVisible}
          globalMenuVisibleChange={setGlobalMenuVisible}
          globalMenu={filterMenuList}
          extraRender={ExtraRender}
          globalMenuClick={linkTo.bind(this)}
          handleCheckPinned={handleCheckPinned.bind(this)}
          customMenuClick={linkTo.bind(this)}
          globalMenuTitle={locale.globalMenuTitle}
          helpVisible={helpVisible}
          setHelpVisible={setHelpVisible}
          customMenuVisible={customMenuVisible}
          setCustomMenuVisible={setCustomMenuVisible}
          customMenu={filterFormatMoreCustomMenu}
          isGetIntoPortal={isGetIntoPortal}
          setIsGetIntoPortal={setIsGetIntoPortal}
          {...language}
          isInside={userInfo.employeeType === 'inside'}
          noBoss={noBoss}
          curItem={curItem}
          downloadCenterUrl={
            noBoss
              ? downloadCenterUrl
              : `${PARK_API}${window.dworkPathnameMap.park}/downloadCenter`
          }
          search={search}
          setSearch={setSearch}
          searchConversationId={searchConversationId}
          setSearchConversation={setSearchConversation}
          employeeId={
            userInfo.employeeId || Number(window?._GATEWAY_PF?.workerNo)
          }
          currentPageProductCode={currentPageProductCodeList?.[0]}
          loadToDo={loadToDo}
        />
      </div>
      <CnShell
        headerVisible={false}
        sideBarVisible={!!filterSideBarMenu?.length}
        sideBarProps={{
          selectedMenuKey: currentPageMenuCode,
          menu: filterSideBarMenu,
          onSelectMenuItem: (item) => {
            if (item.menuUrl) {
              window.location.href = item.menuUrl;
              setGlobalMenuVisible(false);
            }
          },
          defaultMenuCollapse: true,
        }}
      >
        {children}
      </CnShell>
      <PinDialog
        show={showDialog}
        operateItem={operateItem}
        pinnedMenuList={filterFormatMoreCustomMenu.slice(
          1,
          filterFormatMoreCustomMenu.length,
        )}
        onConfirmPin={handlePinned}
        onClose={handleCloseDialog}
      />
      <Dialog
        visible={visible}
        onClose={() => setVisible(false)}
        footer={false}
      >
        <div className='login_div'>
          <iframe
            title='login'
            src={apiBaseUrlLogin()}
            width='100%'
            height='370px'
            scrolling='no'
            frameBorder='0'
            id='loginBox'
          />
        </div>
      </Dialog>
      {listVisible ? (
        <List
          visible={listVisible}
          onClose={() => {
            setListVisible(false);
          }}
          employeeId={
            userInfo.employeeId || Number(window?._GATEWAY_PF?.workerNo)
          }
        />
      ) : null}
    </div>
  );
}

PortalCommonHeader.defaultProps = {
  noBoss: false,
  downloadCenterUrl: '',
};
