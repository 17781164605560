/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { CnTooltip } from '@cainiaofe/cn-ui';
import './index.scss';

const LoneText = (props) => {
  return (
    <CnTooltip
      className='custom-balloon-tooltip'
      triggerType='hover'
      trigger={
        <span
          className='lone-text'
          style={{
            WebkitLineClamp: props.line,
            display: '-webkit-box',
            wordBreak: 'normal',
          }}
        >
          {props.content}
        </span>
      }
      style={{
        zIndex: 2000,
        maxHeight: '70vh',
        overflow: 'auto',
        maxWidth: '70vw',
      }}
      v2
    >
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: props.trigger || props.content,
        }}
        style={{ lineHeight: '17px', fontSize: 12, whiteSpace: 'pre-wrap' }}
      />
    </CnTooltip>
  );
};

LoneText.defaultProps = {
  line: 2,
};

export default LoneText;
