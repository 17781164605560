/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
// eslint-disable-next-line import/no-cycle
import Index from 'src';
import { useLanguage } from './i18n';
import { request, cnOptions, queryLoginInfo, } from './view/header/services/index.js';
export const dworkPathnameMap = {
    home: '/dwork/win/boss/home/cone',
    basic: '/dwork/win/boss/basic/cone',
    basicNext: '/dwork/win/boss/basic/next',
    site: '/dwork/win/boss/site/cone',
    siteNext: '/dwork/win/boss/site/next',
    building: '/dwork/win/boss/building/cone',
    buildingNext: '/dwork/win/boss/building/next',
    cost: '/dwork/win/boss/cost/cone',
    costNext: '/dwork/win/boss/cost/next',
    purchase: '/dwork/win/boss/purchase/next',
    operation: '/dwork/win/boss/operation/next/page',
    dh: '/dwork/win/boss/dh/next',
    expand: '/dwork/win/boss/dh/next',
    rent: '/rent/#',
    rentNext: '/rent/next',
    asset: '/asset/next',
    park: '/park/pc/#',
    parkPlatform: '/platform/#',
    parkLowCode: '/park/park_lowCode/#',
    parkNext: '/park/next/page',
    vision: '/#',
    visionNext: '/cone/next',
    data: '/inc/home/#',
    dataLowCode: '/lowcode/#',
};
// @ts-ignore
window.dworkPathnameMap = dworkPathnameMap;
const formatPathname = [
    {
        cone: ['/'],
        origin: ['cnstr', 'boss'],
        pathname: '/dwork/win/boss/home/cone',
    },
    {
        cone: ['/basic', '/basic/'],
        origin: ['cnstr', 'boss'],
        next: '/basic/next',
        pathname: '/dwork/win/boss/basic',
    },
    {
        cone: ['/site', '/site/'],
        origin: ['cnstr', 'boss'],
        next: '/site/next',
        pathname: '/dwork/win/boss/site',
    },
    {
        cone: ['/building', '/building/'],
        origin: ['cnstr', 'boss'],
        next: '/building/next',
        pathname: '/dwork/win/boss/building',
    },
    {
        cone: ['/cost', '/cost/'],
        origin: ['cnstr', 'boss'],
        next: '/cost/next',
        pathname: '/dwork/win/boss/cost',
    },
    {
        origin: ['cnstr', 'boss'],
        next: '/purchase/next',
        pathname: '/dwork/win/boss/purchase/next',
    },
    {
        origin: ['cnstr', 'boss'],
        next: '/operation/next/page',
        pathname: '/dwork/win/boss/operation/next/page',
    },
    {
        origin: ['cnstr', 'boss'],
        next: '/dh/next',
        pathname: '/dwork/win/boss/dh/next',
    },
    {
        origin: ['cnstr', 'boss'],
        next: '/expand/next',
        pathname: '/dwork/win/boss/expand/next',
    },
    {
        cone: ['/rent', '/rent/'],
        origin: ['cnstr', 'boss'],
        next: '/rent/next',
        pathname: '/dwork/win/boss/rent',
        notUpgrade: true,
    },
    {
        origin: ['cnstr', 'boss', 'asset'],
        next: '/asset/next',
        pathname: '/dwork/win/boss/asset/next',
        notUpgrade: true,
    },
    {
        origin: ['cnstr', 'boss', 'asset'],
        next: '/cp/asset/next',
        pathname: '/dwork/win/boss/cp/asset/next',
        notUpgrade: true,
    },
    {
        cone: ['/park/pc', '/park/pc/'],
        origin: ['park'],
        pathname: '/dwork/win/park/pc/cone',
        notUpgrade: true,
    },
    {
        cone: ['/platform', '/platform/'],
        origin: ['park'],
        pathname: '/dwork/win/park/platform/cone',
        notUpgrade: true,
    },
    {
        cone: ['/park/park_lowCode', '/park/park_lowCode/'],
        origin: ['park'],
        pathname: '/dwork/win/park/park_lowCode/next',
        notUpgrade: true,
    },
    {
        origin: ['park'],
        next: '/park/next/page',
        pathname: '/dwork/win/park/next/page',
        notUpgrade: true,
    },
    {
        cone: ['/'],
        origin: ['vision'],
        next: '/cone/next',
        pathname: '/dwork/win/vision/home',
        notUpgrade: true,
    },
    {
        cone: [
            '/inc/home',
            '/inc/home/',
            '/inc/guoshou',
            '/inc/guoshou/',
            '/data',
            '/data/',
        ],
        origin: ['vision'],
        pathname: '/dwork/win/vision/data/cone',
        notUpgrade: true,
    },
    {
        cone: ['/lowcode', '/lowcode/'],
        origin: ['vision'],
        pathname: '/dwork/win/vision/lowcode/next',
        notUpgrade: true,
    },
];
let hs;
let cs;
const us = { other: [] };
let logs;
const log = () => {
    logs && clearTimeout(logs);
    logs = setTimeout(() => {
        // eslint-disable-next-line no-console
        console.log('cone-container-custom-shell', hs);
        // eslint-disable-next-line no-console
        console.log('custom-get-menu', cs);
        // eslint-disable-next-line guard-for-in
        for (const key in us) {
            us[key] = [...new Set(us[key])];
            if (key === 'other') {
                us[key].sort();
            }
        }
        // eslint-disable-next-line no-console
        console.log('us', us);
    }, 5000);
};
log();
// 处理菜单url
export const handleFormatMenuUrl = (tree, key = 'menuUrl') => {
    let arr = tree;
    if (typeof tree === 'string') {
        arr = [{ [key]: tree }];
    }
    arr?.forEach((item, i) => {
        try {
            let handle = false;
            if (item[key]?.startsWith('/')) {
                // eslint-disable-next-line no-param-reassign, @typescript-eslint/restrict-plus-operands
                item[key] = location.origin + item[key];
            }
            if (item[key] && item[key].startsWith('http')) {
                const u = new URL(item[key]);
                formatPathname.forEach(({ cone, next, pathname, origin, notUpgrade }) => {
                    if (!origin ||
                        (origin.length &&
                            origin.findIndex((ele) => u.origin.includes(ele)) !== -1)) {
                        if (cone && cone.includes(u.pathname)) {
                            const hash = u.hash.replace('#', '').split('?');
                            u.hash = '';
                            if (hash[1]) {
                                u.search += u.search ? `&${hash[1]}` : `?${hash[1]}`;
                            }
                            const pe = `${pathname}${next ? '/cone' : ''}`;
                            u.pathname = `${pe}${hash[0] || ''}`;
                            if (!notUpgrade) {
                                // eslint-disable-next-line no-param-reassign
                                item[key] = u.href;
                            }
                            if (!us[pe])
                                us[pe] = [];
                            us[pe].push(u.href);
                            handle = true;
                        }
                        else if (next && u.pathname.startsWith(next)) {
                            const pe = `${pathname}${cone ? '/next' : ''}`;
                            u.pathname = u.pathname.replace(next, pe);
                            if (!notUpgrade) {
                                // eslint-disable-next-line no-param-reassign
                                item[key] = u.href;
                            }
                            if (!us[pe])
                                us[pe] = [];
                            us[pe].push(u.href);
                            handle = true;
                        }
                    }
                });
            }
            if (!handle && item[key]) {
                us.other.push(item[key]);
            }
            if (item.children?.length) {
                handleFormatMenuUrl(item.children);
            }
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
        if (i === arr.length - 1) {
            log();
        }
    });
    if (typeof tree === 'string') {
        return arr[0][key];
    }
};
// @ts-ignore
window.handleFormatMenuUrl = handleFormatMenuUrl;
// 自定义跳转
// @ts-ignore
window.customJump = (params) => {
    let pathname = params?.pathname || params;
    if (pathname?.startsWith('http')) {
        const u = new URL(pathname);
        if (u.origin === location.origin) {
            pathname = u.href.replace(u.origin, '');
        }
    }
    const siteCode = pathname.split('/')?.[4];
    const currentSiteCode = location.pathname.split('/')?.[4];
    // @ts-ignore
    if (siteCode === currentSiteCode && window.jumpTo) {
        if (params instanceof Object) {
            // eslint-disable-next-line no-param-reassign
            params.pathname = pathname;
        }
        // @ts-ignore
        window.jumpTo(params);
    }
    else if (params?.type) {
        window.open(pathname);
    }
    else {
        location.href = pathname;
    }
};
// @ts-ignore
window.winJumpTo = window.customJump;
// 判断老页面跳转新页面
export const customJumpHref = (path) => {
    const p = path
        ? `${path.startsWith('http') ? '' : location.origin}${path}`
        : window.location.href;
    const currentHref = handleFormatMenuUrl(p);
    if (p !== currentHref) {
        if (path) {
            return currentHref;
        }
        window.location.href = currentHref;
    }
};
customJumpHref('');
const oldPushState = history.pushState;
history.pushState = function (...arg) {
    // 在这里执行你希望在pushState时运行的代码
    const path = customJumpHref(arg?.[2]);
    if (path) {
        // @ts-ignore
        window.customJump(path);
    }
    else {
        // 保证原始的pushState功能依然正常工作
        return oldPushState.apply(history, arg);
    }
};
const { tenantCode = 'dwork', appPath = 'win',
// loginType,
// workerNo,
 } = (window._GATEWAY_PF || {});
// 菜单请求
export const sendMenuRequest = async () => {
    try {
        const res = await request({
            // url: `/${tenantCode}/${appPath}/getMenuByMember`,
            url: `/basic/menu/cn/getMenuByMember`,
            method: 'post',
            data: {
                tenantCode,
                appCode: appPath,
                productCode: location.pathname.split('/')?.[3] || undefined,
                siteCode: location.pathname.split('/')?.[4] || undefined,
                // siteCode: appPath,
                // loginType: ['CAINIAO', 'CN'].includes(loginType) ? 'CN' : loginType,
                // accountType: ['CAINIAO', 'CN'].includes(loginType) ? 'CN' : loginType,
                // userId: workerNo,
                // userTenant: '',
            },
        });
        const { data = {}, errorCode, errorMsg } = res?.data || {};
        if (cnOptions.loginCallback(errorCode, errorMsg, res))
            return null;
        cs = data;
        return data;
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
};
// 自定义菜单
export const customGetMenu = async () => {
    try {
        // 获取登录人信息
        queryLoginInfo();
        return await sendMenuRequest();
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
};
// 自定义shell
export const coneContainerCustomShell = ({ children, headerProps }) => {
    // const { menu, menuOnSelect, menuSelectedKey } = headerProps; // 参照CnShell组件的headerProps属性中的这些字段的数据结果
    hs = headerProps;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const language = useLanguage();
    const isPark = location.origin.includes('park');
    const isVision = location.origin.includes('vision');
    const menuCodeList = ['DWORK_CNSTR_GCGL_TASK', 'DWORK_CNSTR_COST_CALCULATE'];
    // 不显示导航
    if (location.href.includes('hideNav=true') ||
        location.pathname.includes(`${dworkPathnameMap.home}/board`)) {
        return children;
    }
    return (React.createElement(Index, { ...language, 
        // @ts-ignore
        noBoss: isPark || isVision, useHelp: isPark || isVision, sideBarMenuCodeList: menuCodeList, downloadCenterUrl: isPark ? `${dworkPathnameMap.park}/downloadCenter` : '' }, children));
};
