/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-catch */
import React, { useState } from 'react';
import { Dialog, Button, CnMessage } from '@cainiaofe/cn-ui';
import copy from 'copy-to-clipboard';
import $i18n from '@alife/panda-i18n';
import zhCN from '../../../locale/zh-CN';
import enUS from '../../../locale/en-US';
import Cookie from 'js-cookie';

// 多语言列表
export const langZh = 'zh-CN';
export const langEn = 'en-US';
export const isZh = $i18n.getLang() === langZh;
export const isEn = $i18n.getLang() === langEn;
export const locale = isEn ? enUS : zhCN;

// 日常，外部，内部
const hostnames = ['.cainiao.test', '.cainiao.com', '.cainiao-inc.com'];
// 获取子域名
const domain = hostnames.find((name) =>
  window.location.hostname.includes(name),
);

export function customSetLang(lang) {
  if (window?.__PANDA?.cookieKey === 'x-hng') {
    // 设置自定义lang
    Cookie.set('lang', lang, { expires: 17.5 });
  } else {
    // 设置默认x-hng
    Cookie.set('x-hng', `lang=${lang}&domain=${window.location.hostname}`, {
      expires: 17.5,
    });
  }
  // 设置默认子域名x-hng
  Cookie.set('x-lang', lang, { expires: 17.5, domain });

  // bucket切换
  const regions = { 'zh-CN': 'CN_HANGZHOU', 'en-US': 'AP_SOUTHEAST_1' };
  const region = regions[lang];
  Cookie.set('boss-region', region, { expires: 17.5 });

  $i18n.setLang(lang);
}

export function getDefaultLanguage() {
  let lang = Cookie.get('x-lang') || $i18n.getLang();
  if (lang !== langZh && lang !== langEn) {
    lang = langZh;
  }
  customSetLang(lang);
  return lang;
}

export function setLanguage(lang) {
  // eslint-disable-next-line no-console
  console.log('set lang', lang);
  customSetLang(lang);
  window.location.reload();
}

export function useLanguage() {
  const current = getDefaultLanguage();
  const currentLangInfo = {
    menuCode: current,
    menuTitle: current === langZh ? '中文 (简体)' : 'English',
    menuIcon:
      current === langZh
        ? 'https://img.alicdn.com/imgextra/i2/O1CN01MLUzHf21IeJS1tHYC_!!6000000006962-55-tps-16-16.svg'
        : 'https://img.alicdn.com/imgextra/i3/O1CN01I73Teq1tEyC2aDuBG_!!6000000005871-55-tps-16-16.svg',
  };
  const [langVisible, setLangVisible] = useState();
  const langList = [
    { menuUrl: true, menuTitle: '中文 (简体)', menuCode: langZh },
    { menuUrl: true, menuTitle: 'English', menuCode: langEn },
  ];
  return {
    langList,
    langVisible,
    setLangVisible,
    currentLangInfo,
    onLangChange: ({ menuCode }) => setLanguage(menuCode),
  };
}

// eslint-disable-next-line no-nested-ternary
// 系统环境
export const env =
  location.hostname.includes('test') ||
  location.hostname.includes('net') ||
  location.hostname.includes('localhost')
    ? 'daily'
    : location.hostname.includes('pre')
    ? 'pre'
    : 'prod';

// park
export const PARK_API = {
  daily: 'https://park.cainiao.test',
  pre: 'https://pre-park.cainiao.com',
  prod: 'https://park.cainiao.com',
}[env];

// 内部-园区建设
export const PARK_STAFF_API = {
  daily: 'https://dwork-cnstr-staff.cainiao.test',
  pre: 'https://pre-boss.cainiao-inc.com',
  prod: 'https://boss.cainiao-inc.com',
}[env];

// 外部部-园区运营
export const PARK_CP_API = {
  daily: 'https://dwork-cnstr-cp.cainiao.test',
  pre: 'https://pre-cnstr.cainiao.com',
  prod: 'https://boss.cainiao.com',
}[env];

// 默认工作台域名
export const winOrigin = (() => {
  return {
    daily: 'https://vision.cainiao.test',
    pre: 'https://pre-win.cainiao-inc.com',
    prod: 'https://win.cainiao-inc.com',
  }[env];
})();

// boss接口域名
export const bossOrigin = (() => {
  const { origin } = window.location;
  if (['cnstr', 'boss'].findIndex((u) => origin.includes(u)) !== -1) {
    // boss页面默认取页面的域名
    return origin;
  } else if (
    origin.includes('-inc.com') ||
    origin.includes('-asset.cainiao.test')
  ) {
    // win、vision取boss内部buc域名
    return PARK_STAFF_API;
  }
  // 其他情况取boss外部cainiao域名
  return PARK_CP_API;
})();

// asset接口域名
export const assetOrigin = (() => {
  if (
    window.location.origin.includes('-cp.cainiao.test') ||
    window.location.origin.includes('cainiao.com')
  ) {
    return {
      test: '//dwork-asset-cp.cainiao.test',
      pre: '//pre-asset.cainiao.com',
      pro: '//asset.cainiao.com',
    }[env];
  }
  return {
    daily: '//dwork-asset.cainiao.test',
    pre: '//pre-asset.cainiao-inc.com',
    prod: '//asset.cainiao-inc.com',
  }[env];
})();

// 登录地址
// export const apiBaseUrlLogin = () => {
//   const urls = {
//     daily: `https://mozi-login.alibaba.net/ssoLogin.htm?APP_NAME=cn-groot&REALM_ID=10000765078&BACK_URL=${window.location.href}`,
//     pre: `https://pre-mozi-login.alibaba-inc.com/ssoLogin.htm?APP_NAME=cn-groot&BACK_URL=${window.location.href}`,
//     prod: `https://mozi-login.alibaba-inc.com/ssoLogin.htm?APP_NAME=cn-groot&REALM_ID=10007800635&BACK_URL=${window.location.href}`
//   };
//   return urls[env]
// }

export const apiBaseUrlLogin = () => {
  const urls = {
    daily: `https://cnlogin.cainiao.test/miniLogin?isNewLogin=true&showin=true&showdd=true&redirectURL=${window.location.href}`,
    pre: `https://pre-cnlogin.cainiao.com/miniLogin?isNewLogin=true&showin=true&showdd=true&redirectURL=${window.location.href}`,
    prod: `https://cnlogin.cainiao.com/miniLogin?isNewLogin=true&showin=true&showdd=true&redirectURL=${window.location.href}`,
  };
  return urls[env];
};

// 登出地址
// export const apiBaseUrlLogout = () => {
//   const urls = {
//     daily: `https://park.taobao.net/moziSSOLogout.htm?APP_NAME=cn-groot&BACK_URL=${window.location.href}`,
//     pre: `https://pre-park.cainiao.com/moziSSOLogout.htm?APP_NAME=cn-groot&BACK_URL=${window.location.href}`,
//     prod: `https://park.cainiao.com/moziSSOLogout.htm?APP_NAME=cn-groot&BACK_URL=${window.location.href}`
//   };
//   return urls[env]
// }

export const apiBaseUrlLogout = () => {
  const urls = {
    daily: `http://cnlogin.cainiao.test/logout?redirectURL=${window.location.href}`,
    pre: `http://pre-cnlogin.cainiao.com/logout?redirectURL=${window.location.href}`,
    prod: `http://cnlogin.cainiao.com/logout?redirectURL=${window.location.href}`,
  };
  return urls[env];
};

// mozi登录
export const moziLogin = () => {
  try {
    window.location.href = apiBaseUrlLogin();
  } catch (e) {
    throw e;
  }
};

// mozi登出
export const moziLogout = () => {
  try {
    window.location.href = apiBaseUrlLogout();
  } catch (e) {
    throw e;
  }
};

// Alert弹窗
export const popupShow = (content, isSuccess = true) => {
  const dialog = Dialog.show({
    overlayProps: {
      className: `Portal_dialog_show_next ${
        !isSuccess && 'Portal_dialog_show_next_error'
      }`,
    },
    closeMode: ['close', 'esc', 'mask'],
    content: (
      <>
        <div className='icon_img'>
          <img
            src={
              isSuccess
                ? 'https://img.alicdn.com/imgextra/i4/O1CN014JIbas1TuTrap1oMK_!!6000000002442-55-tps-56-56.svg'
                : 'https://img.alicdn.com/imgextra/i2/O1CN01CEi9sF1sGVgyHwH9P_!!6000000005739-55-tps-56-56.svg'
            }
          />
        </div>
        <div className='content'>{content}</div>
      </>
    ),
    footer: isSuccess ? (
      <Button onClick={() => dialog.hide()}>{locale.confirm}</Button>
    ) : (
      false
    ),
  });
  !isSuccess &&
    setTimeout(() => {
      dialog.hide();
    }, 600000);
};

export const proxyOuterDomain = (msg) => {
  if (msg) {
    const url = `${msg}${encodeURIComponent(
      encodeURIComponent(window.parent.location.href),
    )}`;
    window.parent.location.href = url;
    return true;
  }
  return false;
};

// 获取当前页面的hash可能的集合
export const getHashs = () => {
  const pathname1 = `${location.pathname}${location.search}${location.hash}`;
  const pathname2 = `${location.pathname}${location.hash}`;
  const pathname3 = `${location.pathname}${location.hash.split('?')[0]}`;
  const pathname4 = `${location.pathname}${location.search}${
    location.hash.split('?')[0]
  }`;
  return [pathname1, pathname2, pathname3, pathname4];
};

// 获取菜单下所有子页面
export const getChildLinks = (item, links) => {
  item.menuUrl && links.push(item.menuUrl);
  item?.children?.forEach((child) => {
    child.menuUrl && links.push(child.menuUrl);
    child?.children?.length && getChildLinks(child, links);
  });
};

// 去掉域名
export const getPath = (link) => {
  let path = link;
  try {
    if (path?.includes('http')) {
      const url = new URL(link);
      path = url.href.replace(url.origin, '');
    }
  } catch (e) {
    path = link;
  }
  return path;
};

// ding一级导航添加线
export const checkIsCurrentMenu = (item) => {
  const hash = getHashs();
  const links = [];
  getChildLinks(item, links);
  for (const link of links) {
    if (hash.includes(getPath(link))) return true;
  }
};

// 获取当前页面对应菜单下关联的key
export const getCurrentActiveByKey = (list) => {
  for (const item of list) {
    if (checkIsCurrentMenu(item)) return item.key;
  }
};

// 获取当前页面对应菜单下关联的menuCode
export const getCurrentActiveByMenuCode = (list, curItems) => {
  const hash = getHashs();
  for (const item of list) {
    if (hash.includes(getPath(item.menuUrl))) {
      curItems?.push(item);
      window.MENUCODE = curItems?.[0]?.menuCode;
      window.productCodeList = curItems?.[0]?.productCodeList;
      return;
    } else if (item.children instanceof Array) {
      getCurrentActiveByMenuCode(item.children, curItems);
    }
  }
};

// 判断并返回当前页面左侧导航父级menuCode
export const getShowSideBarByCode = (
  currentPageMenuCode,
  sideBarMenuCodeList = [],
  menuList = [],
) => {
  let sideBarMenuCode = '';
  function formatGetCodeChild(fatherCode, list, allList) {
    allList.forEach((item) => {
      if (item.menuCode === fatherCode && item.children instanceof Array) {
        list.push(...item.children);
      } else if (item.children instanceof Array) {
        formatGetCodeChild(fatherCode, list, item.children);
      }
    });
  }
  function formatGetCodeChildCode(list, allList) {
    allList.forEach((item) => {
      list.push(item.menuCode);
      if (Array.isArray(item.children)) {
        formatGetCodeChildCode(list, item.children);
      }
    });
  }
  const currentChild = [];
  for (const code of sideBarMenuCodeList) {
    const codeChild = [];
    const codeChildCode = [];
    formatGetCodeChild(code, codeChild, menuList);
    formatGetCodeChildCode(codeChildCode, codeChild);
    if (codeChildCode.includes(currentPageMenuCode)) {
      sideBarMenuCode = code;
      currentChild.push(...codeChild);
      return { sideBarMenuCode, currentChild };
    }
  }
  return { sideBarMenuCode, currentChild };
};

// 过滤掉按钮和页面 只显示菜单
export const filterTreeGetMenu = (list) => {
  const treeList = JSON.parse(
    JSON.stringify(list.filter((child) => child.menuType === 'MENU')),
  );
  function filterChildren(item) {
    if (Array.isArray(item.children)) {
      // eslint-disable-next-line no-param-reassign
      item.children = item.children.filter(
        (child) => child.menuType === 'MENU',
      );
      item.children.forEach((child) => {
        filterChildren(child);
      });
    }
  }
  treeList.forEach((item) => {
    filterChildren(item);
  });
  return treeList;
};

/**
 * 复制内容
 * @param {String} text
 */
export const copyText = (text, message) => {
  copy(text);
  if (message) CnMessage.success(message);
};

// 禁用a标签默认事件
export const preventDefault = (menuUrl, e) => {
  if (
    (window.location.origin.includes('-inc.com') &&
      typeof menuUrl === 'string' &&
      menuUrl.includes('cainiao.com') &&
      e) ||
    (typeof menuUrl === 'string' && menuUrl?.includes(location.origin))
  ) {
    e.preventDefault();
  }
};

export function redirectURL(url, openUrl, setCustomMenuVisible, e) {
  const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.platform);
  const modifier = e?.[isMac ? 'metaKey' : 'ctrlKey'];
  if (url?.includes(location.origin)) {
    const u = new URL(url);
    window.customJump({
      pathname: u.href.replace(u.origin, ''),
      type: modifier ? '_blank' : '',
    });
    setCustomMenuVisible && setCustomMenuVisible({});
  } else if (
    window.location.origin.includes('-inc.com') &&
    url.includes('cainiao.com')
  ) {
    const redirectUrl = `https://cnlogin.cainiao.com/bucTrustLogin?redirectURL=${encodeURIComponent(
      encodeURIComponent(url),
    )}`;
    if (openUrl || modifier) {
      window.open(redirectUrl);
    } else {
      window.location.href = redirectUrl;
    }
  } else if (openUrl) {
    window.open(openUrl);
  }
}
