import { useState } from 'react';
import $i18n from '@alife/panda-i18n';
// 多语言列表
export const langZh = 'zh-CN';
export const langEn = 'en-US';
export function getDefaultLanguage() {
    let lang = $i18n.getLang();
    if (lang !== langZh && lang !== langEn) {
        lang = langZh;
    }
    $i18n.setLang(lang);
    return lang;
}
const defaultLang = getDefaultLanguage();
// eslint-disable-next-line no-console
console.log('lang', defaultLang);
export function setLanguage(lang) {
    // eslint-disable-next-line no-console
    console.log('set lang', lang);
    $i18n.setLang(lang);
    window.location.reload();
}
export function useLanguage() {
    const current = getDefaultLanguage();
    const currentParkInfo = {
        label: current === langZh ? '中文 (简体)' : 'English',
    };
    const [selectParkVisible, onParkVisibleChange] = useState();
    const parkList = [
        { link: true, label: '中文 (简体)', value: langZh },
        { link: true, label: 'English', value: langEn },
    ];
    return {
        hasParkSelect: true,
        currentParkInfo,
        selectParkVisible,
        onParkVisibleChange,
        parkList,
        onParkSelectChange: ({ value }) => setLanguage(value),
    };
}
