/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react';
import { CnBox } from '@cainiaofe/cn-ui';
import MenuItem from '../MenuItem';
import { getCurrentActiveByKey } from './../shared';

export default function CustomerMenu({
  dataSource = [],
  onSelect,
  onTackSelect,
  expandTriggerType,
  renderSubPanel,
  customMenuClick,
  globalMenuTitle,
}) {
  const [active, setActive] = useState('');
  useEffect(() => {
    const key = getCurrentActiveByKey(dataSource);
    setActive(key);
  }, [dataSource]);
  const childItem = useMemo(
    () =>
      dataSource.find(
        (item) =>
          active === item.key && item.children && item.children.length > 0,
      ),
    [active, dataSource],
  );

  return (
    <>
      {dataSource.length > 0 && (
        <CnBox style={{ width: childItem ? '100vw' : '' }} direction='row'>
          <div className='nav-custom-menu'>
            <div className='nav-custom-menu-title'>
              <h3>{globalMenuTitle}</h3>
            </div>
            {dataSource.map((item, index) => (
              <React.Fragment key={item.key || index}>
                <div
                  onClick={() => {
                    expandTriggerType === 'click' && setActive(item.key);
                  }}
                  onMouseOver={() => {
                    expandTriggerType === 'hover' && setActive(item.key);
                  }}
                >
                  <MenuItem
                    text={item.menuTitle}
                    icon={item.menuIcon}
                    iconSize={item.iconSize || item.menuIconSize}
                    iconWidth={item.iconWidth || item.menuIconWidth}
                    hasArrow={item.children && item.children.length > 0}
                    isDing={item.isDing}
                    isSelect={active === item.key}
                    onSelect={(e) => {
                      // !(item.children && item.children.length > 0) && onSelect(item);
                      onSelect(item, e);
                    }}
                    onTackSelect={() => {
                      onTackSelect(item, !item.pinned);
                    }}
                    pinned={item.pinned}
                    menuUrl={item.menuUrl}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
          <CnBox
            className='nav-custom-menu-item-children'
            x-if={childItem}
            direction='row'
          >
            {renderSubPanel(
              childItem.children || [],
              customMenuClick,
              {
                ...childItem,
                columnCount: 5,
              },
              false,
              setActive,
            )}
            {/* {dataSource.map((item, index) => {
              return active === item.key &&
                item.children &&
                item.children.length > 0 ? (
                <CustomerMenu
                  key={item.key || index}
                  onSelect={(it) => {
                    onSelect(it);
                  }}
                  dataSource={item.children}
                  expandTriggerType={expandTriggerType}
                  onTackSelect={(it) => {
                    onTackSelect(it, !it.pinned);
                  }}
                />
              ) : null;
            })} */}
          </CnBox>
        </CnBox>
      )}
    </>
  );
}
CustomerMenu.defaultProps = {
  onSelect: () => {},
  onTackSelect: () => {},
  expandTriggerType: 'hover',
};
