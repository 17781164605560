/* eslint-disable react/prop-types */
import { Row, Col } from '@cainiaofe/cn-ui';
import { isMobile } from '../EspCommonLayout';

const MobileRow = (props) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(24, 1fr)',
        ...(props.style || {}),
      }}
      className={props.className}
    >
      {props.children}
    </div>
  );
};

const MobileCol = (props) => {
  return (
    <div
      style={{
        gridColumn: `span ${props.span || 24}`,
        ...(props.style || {}),
      }}
      className={props.className}
    >
      {props.children}
    </div>
  );
};

export const CnRow = (props) => {
  const R = isMobile ? MobileRow : Row;
  return <R {...props}>{props.children}</R>;
};

export const CnCol = (props) => {
  const C = isMobile ? MobileCol : Col;
  return <C {...props}>{props.children}</C>;
};
